import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

// Create the context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();

  // Initialize auth state by checking localStorage for user and token
  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('authenticatedUser'));
    const token = localStorage.getItem('authToken');
    if (savedUser && token) {
      setIsAuthenticated(true);
      setUser(savedUser);
    }
    setLoading(false);
  }, []);

  // Method to log in the user, wrapped with useCallback for stability
  const login = useCallback(({ email, firstName, token }) => {
    const user = { email, firstName };
    setIsAuthenticated(true);
    setUser(user);
    localStorage.setItem('authenticatedUser', JSON.stringify(user));
    localStorage.setItem('authToken', token);
  }, []);

  // Method to log out the user
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('authenticatedUser');
    localStorage.removeItem('authToken');
    client.clearStore();
  }, [client]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
