import React from 'react';
import { Box } from '@mui/material';
import BottomNavBar from './components/BottomNavBar';

const AppLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Full viewport height
      }}
    >
      {/* Scrollable Content */}
      <Box
        sx={{
          flexGrow: 1, // Content grows to fill space
          overflowY: 'auto', // Enables vertical scrolling
          paddingBottom: '80px', // Space for the BottomNavBar
        }}
      >
        {children}
      </Box>

      {/* Bottom Navigation */}
      <BottomNavBar />
    </Box>
  );
};

export default AppLayout;
