import React from 'react';
import './LandingPage.css';
import GlideLanding from '../assets/GlideLanding.webp';
import { FaBell, FaUserCircle, FaSearch, FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {

    const navigate = useNavigate();

  return (
    <div className="landing-page">
      {/* Header */}
      <header className="header">
        <div className="header-logo">Glide</div>
        {/* <nav className="header-nav">
          <a href="#event">Event</a>
          <a href="#messages">Messages</a>
          <a href="#resources">Resources</a>
          <a href="#more">...</a>
        </nav> */}
        <div className="header-icons">
          <FaSearch className="icon" />
          <FaBell className="icon" />
          <FaUserCircle className="icon" />
        </div>
        <FaBars className="menu-icon" />
      </header>

      {/* Main Content with Welcome and About Us */}
      <main className="main-content">
        <div className="welcome-section">
          <h1>Welcome</h1>
          <p>Effortless Event Entry, Elevated Experiences</p>
          <div className="buttons">
          <button className="join-btn" onClick={() => navigate('/sign-up')}>Join Now</button>
          <button className="login-btn" onClick={() => navigate('/sign-in')}>Log In</button>
          </div>
        </div>
        <div className="image-container">
          <img src={GlideLanding} alt="Event Entry" className="main-image" />
        </div>
      </main>

      {/* About Us Section */}
      <section className="about-us">
        <div className="about-text">
          <h2>About Us</h2>
          <p>
            At Glide Entry, we simplify event access with our fast and secure facial recognition technology.
            Our mission is to make event check-ins seamless, giving attendees a VIP experience from the start.
            With a commitment to innovation, privacy, and compliance, we set the standard for effortless and
            reliable entry solutions.
          </p>
        </div>
        <div className="about-image">
          <img src={GlideLanding} alt="Facial Recognition" />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
