import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavBar from './BottomNavBar';
import './EventsPage.css';
import { useQuery, useMutation } from '@apollo/client';
import defaultImage from '../assets/EventDefault.jpg'; // Import default image
import { CREATE_EVENT_MUTATION } from '../graphql/mutations';
import { GET_EVENTS_QUERY } from '../graphql/queries';

import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
} from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Use a percentage for responsiveness
  maxWidth: '350px', // Restrict the maximum width
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh', // Ensure it doesn't overflow the screen
  overflowY: 'auto', // Add scroll for overflow content
};

const buttonStyle = {
  backgroundColor: '#020420',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1c1c4d',
  },
};

const eventTypeOptions = ['Conference', 'Workshop', 'Meetup', 'Webinar'];

const EventsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventData, setEventData] = useState({
    type: '',
    name: '',
    venue: '',
    description: '',
    startDate: '',
    endDate: '',
    requiresId: false,
    qrCodeEntry: false,
  });

  const [filter, setFilter] = useState('Future'); // Filter state

  // Fetch events
  const { data, loading, error, refetch } = useQuery(GET_EVENTS_QUERY);

  // Use the mutation to create events
  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    onCompleted: () => {
      refetch(); // Refresh the events list after creating an event
      toggleModal();
      setEventData({
        type: '',
        name: '',
        venue: '',
        description: '',
        startDate: '',
        endDate: '',
        requiresId: false,
        qrCodeEntry: false,
      });
    },
    onError: (error) => {
      alert('Error creating event:\n' + error?.graphQLErrors?.[0]?.extensions?.wrongConditions);
    },
  });

  useEffect(() => {
    if (location.state?.openModal) {
      setIsModalOpen(true);
    }
  }, [location.state]);

  const getFilteredEvents = () => {
    const currentTime = new Date();
    if (!data) return [];

    switch (filter) {
      case 'Future':
        return data.getEvents.filter(event => new Date(event.startDate) > currentTime);
      case 'Current':
        return data.getEvents.filter(
          event =>
            new Date(event.startDate) <= currentTime && new Date(event.endDate) >= currentTime
        );
      case 'Past':
        return data.getEvents.filter(event => new Date(event.endDate) < currentTime);
      default:
        return data.getEvents;
    }
  };

  // Toggle modal for creating new events
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEventData({
      ...eventData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedEventData = {
      ...eventData,
      startDate: new Date(eventData.startDate).toISOString(),
      endDate: new Date(eventData.endDate).toISOString(),
    };

    // Create the event using mutation
    await createEvent({ variables: { input: formattedEventData } });
  };

  const handleCardClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  if (loading) return <p>Loading events...</p>;
  if (error) return <p>Error loading events: {error?.graphQLErrors?.[0]?.extensions?.wrongConditions}</p>;

  return (
    <div className="events-page">
      <h1>Events</h1>

      <div className="filter-buttons">
        <button
          onClick={() => setFilter('Future')}
          className={`filter-button ${filter === 'Future' ? 'active' : ''}`}
        >
          Future
        </button>
        <button
          onClick={() => setFilter('Current')}
          className={`filter-button ${filter === 'Current' ? 'active' : ''}`}
        >
          Current
        </button>
        <button
          onClick={() => setFilter('Past')}
          className={`filter-button ${filter === 'Past' ? 'active' : ''}`}
        >
          Past
        </button>
      </div>

      <div className="events-container">
      {getFilteredEvents().map(event => {
  // Get the latest event image or use the default image
  const eventImage = event.eventMedia?.[event.eventMedia.length - 1]?.mediaUrl || defaultImage;

  return (
    <div key={event.id} className="event-card" onClick={() => handleCardClick(event.id)}>
      <div className="event-image-container">
        <img src={eventImage} alt="Event" className="event-image" />
      </div>
      <div className="event-details">
        <h2>{event.name}</h2>
        <p><strong>Venue:</strong> {event.venue}</p>
        <p><strong>Type:</strong> {event.type}</p>
        <p><strong>Start Date:</strong> {new Date(event.startDate).toLocaleString()}</p>
      </div>
    </div>
  );
})}

      </div>

      {/* Floating Action Button */}
      <button className="fab" onClick={toggleModal}>
        Create New Event
      </button>

      {/* Modal for Creating New Event */}
      {isModalOpen && (
        <Modal open={isModalOpen} onClose={toggleModal} aria-labelledby="modal-title">
          <Box sx={modalStyle}>
            <Typography id="modal-title" variant="h6" gutterBottom>
              Create New Event
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Event Name"
                name="name"
                fullWidth
                value={eventData.name}
                onChange={handleChange}
                required
                margin="normal"
              />
              <Autocomplete
                options={eventTypeOptions}
                value={eventData.type}
                onChange={(e, newValue) => setEventData({ ...eventData, type: newValue })}
                renderInput={(params) => (
                  <TextField {...params} label="Event Type" fullWidth required margin="normal" />
                )}
              />
              <TextField
                label="Venue"
                name="venue"
                fullWidth
                value={eventData.venue}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                label="Description"
                name="description"
                fullWidth
                multiline
                rows={4}
                value={eventData.description}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                label="Start Date"
                type="datetime-local"
                name="startDate"
                fullWidth
                value={eventData.startDate}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="End Date"
                type="datetime-local"
                name="endDate"
                fullWidth
                value={eventData.endDate}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="requiresId"
                    checked={eventData.requiresId}
                    onChange={handleChange}
                  />
                }
                label="Requires ID"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="qrCodeEntry"
                    checked={eventData.qrCodeEntry}
                    onChange={handleChange}
                  />
                }
                label="QR Code Entry"
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button type="submit" variant="contained" sx={buttonStyle}>
                  Create Event
                </Button>
                <Button onClick={toggleModal} variant="outlined" color="secondary">
                  Close
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      )}

      {/* Bottom navigation bar */}
      {/* <BottomNavBar /> */}
    </div>
  );
};

export default EventsPage;
