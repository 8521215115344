import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Card
, AppBar, Toolbar, Avatar
} from '@mui/material';
import BottomNavBar from './BottomNavBar';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_EVENTS_QUERY, ORGANIZER_PROFILE_INFO_QUERY, GET_EVENT_QUERY } from '../graphql/queries';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Legend);

const HomePage = () => {
  const navigate = useNavigate();
  const [selectedEventId, setSelectedEventId] = useState(null); // Track selected event

  // Queries to get data
  const { data: userData, loading: userLoading } = useQuery(ORGANIZER_PROFILE_INFO_QUERY);
  const { data: eventsData, loading: eventsLoading } = useQuery(GET_EVENTS_QUERY);
  const { data: eventDetailData, loading: eventDetailLoading } = useQuery(GET_EVENT_QUERY, {
    variables: { getEventInput2: { eventId: selectedEventId } },
    skip: !selectedEventId, // Skip query if no event is selected
  });

  // Set the first event as default selected
  useEffect(() => {
    if (!selectedEventId && eventsData?.getEvents?.length) {
      setSelectedEventId(eventsData.getEvents[0].id);
    }
  }, [eventsData, selectedEventId]);

  // Get current month and year for comparison
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  const handleCreateEvent = () => navigate('/events', { state: { openModal: true } });
  const handleCheckIn = () => navigate('/check-in');

  // Count event types for Doughnut chart
  const eventTypeCounts = eventsData?.getEvents?.reduce((acc, event) => {
    acc[event.type] = (acc[event.type] || 0) + 1;
    return acc;
  }, {});

  const doughnutData = {
    labels: Object.keys(eventTypeCounts || {}),
    datasets: [
      {
        data: Object.values(eventTypeCounts || {}),
        backgroundColor: ['#020420', '#4caf50', '#ff9800', '#f44336'],
        hoverBackgroundColor: ['#020420', '#4caf50', '#ff9800', '#f44336'],
      },
    ],
  };

  // Get user name
  const userName = userData?.me?.preferredName || userData?.me?.firstName || JSON.parse(localStorage.getItem('authenticatedUser')).firstName;

  // Calculate monthly change
  const eventsThisMonth = eventsData?.getEvents?.filter(event => {
    const eventDate = new Date(event.startDate);
    return eventDate.getMonth() === currentMonth && eventDate.getFullYear() === currentYear;
  }).length || 0;

  const eventsLastMonth = eventsData?.getEvents?.filter(event => {
    const eventDate = new Date(event.startDate);
    return eventDate.getMonth() === previousMonth && eventDate.getFullYear() === previousMonthYear;
  }).length || 0;

  const monthChange = eventsThisMonth - eventsLastMonth;

  const allStatuses = ['PENDING', 'APPROVED', 'REJECTED', 'SUBMITTED'];
  const statusCounts = allStatuses.reduce((acc, status) => {
    acc[status] = eventDetailData?.getEvent?.attendees?.filter((attendee) => attendee.status === status).length || 0;
    return acc;
  }, {});
  
  const barData = {
    labels: Object.keys(statusCounts || {}),
    datasets: [
      {
        label: 'Attendees Status',
        data: Object.values(statusCounts || {}),
        backgroundColor: ['orange', 'green', '#FF0000', '#007BFF'],
        hoverBackgroundColor: ['orange', 'green', '#FF0000', '#007BFF'],
      },
    ],
  };

  if (userLoading || eventsLoading) return <p>Loading...</p>;

  return (
    <div className="home-page">
<Box
  sx={{
    padding: 3,
    backgroundColor: '#020420',
    color: '#fff',
    borderRadius: '16px',
    textAlign: 'center',
    width: '100%', // Full width on smaller screens
    maxWidth: { xs: '100%', md: '800px', lg: '1000px' }, // Wider on larger screens
    margin: '0 auto', // Center align the card
  }}
>

        <Typography
  variant="h4"
  fontWeight="bold"
  sx={{
    fontSize: { xs: '1.8rem', md: '2.5rem' }, // Adjust font size for screen size
    textAlign: 'center',
    marginBottom: 1,
  }}
>
  Welcome Back 👋
</Typography>
<Typography
  variant="subtitle1"
  sx={{
    fontSize: { xs: '1rem', md: '1.2rem' },
    textAlign: 'center',
  }}
>
  {userName}
</Typography>
<Box
  sx={{
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' }, // Stacked on mobile, three columns on larger screens
    gap: 3,
    padding: 3,
    backgroundColor: '#020420',
    borderRadius: 3,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginTop: 3,
  }}
>
  {/* Total Events */}
  <Box
    sx={{
      textAlign: 'center',
      padding: 2,
      borderRadius: 2,
      border: '1px solid rgba(255, 255, 255, 0.2)',
    }}
  >
    <Typography variant="h5" fontWeight="bold" color="white">
      Total Events
    </Typography>
    <Typography
      variant="h4"
      fontWeight="bold"
      color="white"
      sx={{ marginTop: 1 }}
    >
      {eventsData?.getEvents?.length || 0}
    </Typography>
    <Typography
      variant="body1"
      color="white"
      sx={{
        marginTop: 1,
        opacity: 0.8,
        fontSize: '0.9rem',
      }}
    >
      {monthChange >= 0 ? `+${monthChange}` : monthChange} this month
    </Typography>
  </Box>

  {/* Create and Manage Events */}
  <Box
    sx={{
      textAlign: 'center',
      padding: 2,
      borderRadius: 2,
      border: '1px solid rgba(255, 255, 255, 0.2)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 2,
    }}
  >
    <button
      style={{
        backgroundColor: '#fff',
        color: '#020420',
        padding: '10px 20px',
        borderRadius: '8px',
        fontSize: '1rem',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClick={handleCreateEvent}
    >
      Create Event
    </button>
    <button
      style={{
        backgroundColor: '#fff',
        color: '#020420',
        padding: '10px 20px',
        borderRadius: '8px',
        fontSize: '1rem',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClick={() => navigate('/events')}
    >
      Manage Events
    </button>
  </Box>

  {/* Check-In */}
  <Box
  sx={{
    textAlign: 'center',
    padding: 2,
    borderRadius: 2,
    border: '1px solid rgba(255, 255, 255, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Vertically centers the content
  }}
>
  <button
    style={{
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: '#fff',
      padding: '10px 20px',
      borderRadius: '8px',
      fontSize: '1rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      border: '1px solid rgba(255, 255, 255, 0.3)',
    }}
    onClick={handleCheckIn}
  >
    Check-In for Event
  </button>
</Box>

</Box>

      </Box>

      {/* Balance Section with Create and Manage buttons */}
      
      <Box
  sx={{
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, // Stacked on mobile, side by side on larger screens
    gap: 4,
    padding: 3,
  }}
>
  <Card
    elevation={3}
    sx={{
      width: '100%',
      padding: 3,
      borderRadius: 2,
      backgroundColor: '#fff',
    }}
  >
    <Typography
      variant="h6"
      sx={{
        textAlign: 'center',
        marginBottom: 2,
        fontWeight: 'bold',
        color: 'primary.main',
      }}
    >
      Event Type Breakdown
    </Typography>
    <Box
      sx={{
        position: 'relative',
        height: { xs: 300, md: 400 },
        width: '100%',
      }}
    >
      <Doughnut
        data={doughnutData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </Box>
  </Card>

  <Card
    elevation={3}
    sx={{
      width: '100%',
      padding: 3,
      borderRadius: 2,
      backgroundColor: '#f9f9f9',
    }}
  >
    <Typography
      variant="h6"
      sx={{
        textAlign: 'center',
        marginBottom: 2,
        fontWeight: 'bold',
        color: 'primary.main',
      }}
    >
      Attendee Status for {eventDetailData?.getEvent?.name || 'Selected Event'}
    </Typography>

    <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
      <InputLabel>Select Event</InputLabel>
      <Select
        value={selectedEventId || ''}
        label="Select Event"
        onChange={(e) => setSelectedEventId(e.target.value)}
      >
        {eventsData?.getEvents?.map((event) => (
          <MenuItem key={event.id} value={event.id}>
            {event.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    <Box
      sx={{
        marginTop: 2,
        padding: 2,
        position: 'relative',
        height: { xs: 300, md: 400 },
        width: '100%',
      }}
    >
      <Bar
        data={barData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
          },
          scales: {
            x: {
              ticks: { font: { weight: 'bold', size: 14 } },
            },
            y: {
              beginAtZero: true,
              ticks: { precision: 0, font: { weight: 'bold', size: 14 } },
            },
          },
        }}
      />
    </Box>
  </Card>
</Box>




      {/* Bottom navigation bar */}
    </div>
  );
};

export default HomePage;
