import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BottomNavBar from './BottomNavBar';
import './EventDetailsPage.css';
import { useQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faClock, faTag, faArrowLeft, faEdit, faTrash, faUserPlus, faUsers, faCheckCircle, faList, faCopy, faCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import defaultImage from '../assets/EventDefault.jpg';
import EditEventModal from './EditEventModal';
import { GET_EVENT_QUERY } from '../graphql/queries';
import { ADD_USER_TO_EVENT_MUTATION, DELETE_EVENT_MUTATION, ADD_USER_SHEET_TO_EVENT, RESEND_VERIFICATION_NOTICE_MUTATION } from '../graphql/mutations';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Box, Typography, TextField, Button } from '@mui/material';
import DOMPurify from 'dompurify';
import Papa from 'papaparse';
import MappingModal from './MappingModal'; 


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'auto',
};

const buttonStyle = {
  backgroundColor: '#020420',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1c1c4d',
  },
};


const EventDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttendeeModalOpen, setIsAttendeeModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [userId, setUserId] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [statusMessage, setStatusMessage] = useState(''); // Result status message
  const [copiedRow, setCopiedRow] = useState(null);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [columnMapping, setColumnMapping] = useState([]); 
  const requiredFields = ['FirstName', 'LastName', 'PreferredName', 'Email', 'PhoneNumber'];
  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false);
  const [resending, setResending] = useState({});


  

  const [addUserSheetToEvent] = useMutation(ADD_USER_SHEET_TO_EVENT);
  const [resendVerificationNotice] = useMutation(RESEND_VERIFICATION_NOTICE_MUTATION);


  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    email: '',
    phoneNumber: '',
  });
  
  const { data, loading: queryLoading, error,refetch } = useQuery(GET_EVENT_QUERY, {
    variables: { getEventInput2: { eventId: id } },
  });

  const totalGuests = data?.getEvent?.attendees ? data.getEvent.attendees.length : 0;
  const verifiedGuests = data?.getEvent?.attendees
    ? data.getEvent.attendees.filter((attendee) => attendee.status === 'APPROVED').length
    : 0;
  

  const [deleteEvent] = useMutation(DELETE_EVENT_MUTATION, {
    variables: { input: { eventId: id } },
    onCompleted: () => navigate('/events'),
    onError: (error) => console.error("Error deleting event:", error?.graphQLErrors?.[0]?.extensions?.wrongConditions),
  });

  const [addUserToEvent] = useMutation(ADD_USER_TO_EVENT_MUTATION, {
    onCompleted: () => {
      setLoading(false);
      setStatusMessage('User added successfully!');
      setFile(null);
      setUserId('');
      setUserInfo({ firstName: '', lastName: '', preferredName: '', email: '', phoneNumber: '' });
    },
    onError: (error) => {
      console.error("Error adding user to event:", error?.graphQLErrors?.[0]?.extensions?.wrongConditions);
      setStatusMessage('Error adding user. Please try again.');
      setLoading(false);
    },
  });

  const handleEdit = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const handleUpdate = (updatedEvent) => {
    refetch(); // Refresh data to reflect the updated event details
  };


  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      deleteEvent();
    }
  };



  const handleAddPeople = () => {
    setStatusMessage(''); // Clear previous message
    setLoading(false);    // Reset loading state
    setIsModalOpen(true); // Open the modal
  };
  const handleShowAttendees = async () => {
    await refetch(); // Refetch the data to get the latest attendee list
    setIsAttendeeModalOpen(true); // Open the attendee modal after refetching
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
  
    if (!uploadedFile) {
      alert('Please select a file.');
      return;
    }
  
    Papa.parse(uploadedFile, {
      header: true,
      complete: (results) => {
        const headers = Object.keys(results.data[0] || {});
        setFileHeaders(headers); // Store headers for dropdown options
        const newColumnMapping = requiredFields.map((field) => {
          const matchedHeader = headers.find((header) =>
            header.toLowerCase().includes(field.toLowerCase())
          );
          return {
            appField: field,
            sheetColumn: matchedHeader || '', // Default to an empty string
          };
        });
        setColumnMapping(newColumnMapping); // Initialize mapping
        setFile(uploadedFile); // Save the file
        setIsMappingModalOpen(true); // Open mapping modal
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error?.graphQLErrors?.[0]?.extensions?.wrongConditions);
        alert('Failed to read the file. Please try again.');
      },
    });
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleAddUserSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    // Ensure birthDate is formatted correctly if it's provided
    const formattedUserInfo = {
      ...userInfo,
      ...(userInfo.birthDate ? { birthDate: `${userInfo.birthDate}T00:00:00Z` } : {}),
    };


    if (file) {
      const sheetInput = {
        eventId: id,
        file,
        columnMapping, // Use the dynamically generated column mapping
      };
  
      try {
        const { data } = await addUserSheetToEvent({ variables: { input: sheetInput } });
        console.log('Sheet successfully added:', data);
        setStatusMessage('Users successfully added from file!');
      } catch (error) {
        console.error('Error adding users from file:', error?.graphQLErrors?.[0]?.extensions?.wrongConditions);
        setStatusMessage('Failed to add users from file.');
      } finally {
        setLoading(false);
      }
    } else {
      const input = {
        eventId: id,
        ...(userId ? { userId } : {}),
        userInfo: Object.fromEntries(Object.entries(formattedUserInfo).filter(([_, v]) => v)),
      };
      addUserToEvent({ variables: { input } });
    }

  
    // const input = {
    //   eventId: id,
    //   ...(file ? { file } : {}),
    //   ...(userId ? { userId } : {}),
    //   userInfo: file ? null : Object.fromEntries(Object.entries(formattedUserInfo).filter(([_, v]) => v)),
    // };
  
  };


  const handleSaveMapping = () => {
    if (columnMapping.some((mapping) => !mapping.sheetColumn)) {
      alert('Please complete the mapping for all required fields.');
      return;
    }
    setStatusMessage('Mapping saved successfully!');
  };

  const handleCopyLink = (rowIndex, link) => {
    navigator.clipboard.writeText(link);
    setCopiedRow(rowIndex);
  
    // Reset the copied state after 2 seconds
    setTimeout(() => setCopiedRow(null), 2000);
  };
  
  
  if (queryLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error loading event details: {error?.graphQLErrors?.[0]?.extensions?.wrongConditions}</p>;
  }


  const event = data?.getEvent;

  if (!event) {
    return <p>Event not found</p>;
  }


  const eventImage = event.eventMedia?.[event.eventMedia.length - 1]?.mediaUrl || defaultImage;
  const lastMediaId = event.eventMedia?.[event.eventMedia.length - 1]?.id || null;


  return (
    <div className="event-details-page">
      <div
        className="event-hero"
        style={{
          backgroundImage: `url(${eventImage})`,
        }}
      >
        <button className="back-button" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h1 className="event-title">{event.name}</h1>
      </div>

      <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
  }}
>
      <div className="event-details-card">
        <div className="event-actions">
          <button className="edit-button" onClick={handleEdit}>
            <FontAwesomeIcon icon={faEdit} className="button-icon" /> Edit
          </button>
          <button className="edit-button" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} className="button-icon" /> Delete
          </button>
        </div>
        {/* Event details */}
        <div className="event-detail">
          <FontAwesomeIcon icon={faTag} className="detail-icon" />
          <span><strong>Type:</strong> {event.type}</span>
        </div>
        <div className="event-detail">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="detail-icon" />
          <span><strong>Venue:</strong> {event.venue}</span>
        </div>
        <div className="event-detail">
  <FontAwesomeIcon icon={faCalendarAlt} className="detail-icon" />
  <span><strong>Start Date:</strong> {new Date(event.startDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' })}</span>
</div>
<div className="event-detail">
  <FontAwesomeIcon icon={faClock} className="detail-icon" />
  <span><strong>End Date:</strong> {new Date(event.endDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' })}</span>
</div>

        <div className="event-detail">
    <FontAwesomeIcon icon={faUsers} className="detail-icon" />
    <span><strong>Total Guests:</strong> {totalGuests}</span>
  </div>
  <div className="event-detail">
    <FontAwesomeIcon icon={faCheckCircle} className="detail-icon" />
    <span><strong>Verified Guests:</strong> {verifiedGuests}</span>
  </div>
  <div className="event-actions">
          <button className="edit-button" onClick={handleAddPeople}>
            <FontAwesomeIcon icon={faUserPlus} className="button-icon" /> Add People
          </button>
          <button className="edit-button" onClick={handleShowAttendees}>
          <FontAwesomeIcon icon={faList} className="button-icon" /> Attendee List
        </button>
        </div>
        <div className="event-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.description) }}>
          {/* <h3>Description</h3>
          <p>{event.description}</p> */}
        </div>
        
      </div>

      {isEditModalOpen && (
        <EditEventModal
        event={event}
        eventImage={eventImage}
        lastMediaId={lastMediaId}
        onClose={handleCloseEditModal}
        onUpdate={handleUpdate}
      />
      )}
      
      {/* Modal for adding users */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="add-people-modal-title">
  <Box sx={modalStyle}>
    <Typography id="add-people-modal-title" variant="h6" gutterBottom>
      Add People to Event
    </Typography>
    {loading && <Typography variant="body2">Loading...</Typography>}
    {statusMessage && <Typography variant="body2" color="success.main">{statusMessage}</Typography>}
    
    <Typography variant="body2" gutterBottom>
      Upload a CSV file or manually enter details to add people.
    </Typography>
    
    {/* CSV Upload Section */}
    <Button
      variant="contained"
      component="label"
      sx={buttonStyle}
      fullWidth
      style={{ marginTop: '1rem' }}
    >
      Upload CSV
      <input type="file" accept=".csv" onChange={handleFileChange} hidden />
    </Button>

    {/* Manual Entry Section */}
    <Typography variant="body2" style={{ marginTop: '1rem' }}>Or enter details manually:</Typography>
    <TextField
      label="First Name"
      name="firstName"
      fullWidth
      value={userInfo.firstName}
      onChange={handleInputChange}
      margin="normal"
    />
    <TextField
      label="Last Name"
      name="lastName"
      fullWidth
      value={userInfo.lastName}
      onChange={handleInputChange}
      margin="normal"
    />
    <TextField
      label="Preferred Name"
      name="preferredName"
      fullWidth
      value={userInfo.preferredName}
      onChange={handleInputChange}
      margin="normal"
    />
    <TextField
      label="Email"
      name="email"
      type="email"
      fullWidth
      value={userInfo.email}
      onChange={handleInputChange}
      margin="normal"
    />
    <TextField
      label="Phone Number"
      name="phoneNumber"
      type="tel"
      fullWidth
      value={userInfo.phoneNumber}
      onChange={handleInputChange}
      margin="normal"
    />

<MappingModal
        isOpen={isMappingModalOpen}
        onClose={() => setIsMappingModalOpen(false)}
        fileHeaders={fileHeaders}
        columnMapping={columnMapping}
        setColumnMapping={setColumnMapping}
        requiredFields={requiredFields}
        handleSaveMapping={handleSaveMapping}
      />

    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
      <Button
        variant="contained"
        onClick={handleAddUserSubmit}
        sx={buttonStyle}
      >
        Add User
      </Button>
      <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
        Close
      </Button>
    </Box>
  </Box>
</Modal>

            {isAttendeeModalOpen && (
        <div className="attendee-modal-overlay">
          <div className="attendee-modal-content">
            <h3>Attendee List</h3>
            <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>First Name</strong></TableCell>
                    <TableCell><strong>Last Name</strong></TableCell>
                    <TableCell><strong>Status</strong></TableCell>
                    <TableCell><strong>Unique Link</strong></TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
  {event.attendees.map((attendee, index) => (
    <TableRow
    key={index}
  >
      <TableCell>{attendee.user.firstName}</TableCell>
      <TableCell>{attendee.user.lastName}</TableCell>
      <TableCell>{attendee.status}</TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <button
            onClick={() => handleCopyLink(index, `https:/app.glidentry.com/validate-link/${attendee.verificationLink}`)}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              marginLeft: '10px',
              color: '#007BFF',
            }}
            title={copiedRow === index ? 'Copied!' : 'Copy Link'}
          >
            <FontAwesomeIcon icon={copiedRow === index ? faCheck : faCopy} color="#000000" />
          </button>
          <button
  onClick={async () => {
    setResending((prev) => ({ ...prev, [attendee.user.id]: true }));
    try {
      await resendVerificationNotice({
        variables: {
          input: {
            eventId: id,
            userId: attendee.user.id,
          },
        },
      });
      // Mark the attendee as successfully resent
      setResending((prev) => ({
        ...prev,
        [attendee.user.id]: false,
      }));
      setCopiedRow(attendee.user.id); // Indicate the checkmark state for the button
      setTimeout(() => setCopiedRow(null), 2000); // Reset state after 2 seconds
    } catch (error) {
      console.error('Error resending verification:', error?.graphQLErrors?.[0]?.extensions?.wrongConditions);
      setResending((prev) => ({
        ...prev,
        [attendee.user.id]: false,
      }));
    }
  }}
  style={{
    background: 'none',
    border: 'none',
    cursor: resending[attendee.user.id] ? 'not-allowed' : 'pointer',
    color: resending[attendee.user.id] ? '#999' : '#007BFF',
  }}
  disabled={resending[attendee.user.id]}
  title={resending[attendee.user.id] ? 'Resending...' : 'Resend Verification'}
>
  {copiedRow === attendee.user.id ? (
    <FontAwesomeIcon icon={faCheck} color="green" /> // Show checkmark after successful resend
  ) : resending[attendee.user.id] ? (
    <FontAwesomeIcon icon={faClock} spin /> // Show spinner while resending
  ) : (
    <FontAwesomeIcon icon={faEnvelope} color="#000000" /> // Default resend icon
  )}
</button>

        </div>
      </TableCell>
    </TableRow>
  ))}
    </TableBody>
              </Table>
            </TableContainer>
            <button onClick={() => setIsAttendeeModalOpen(false)} className="close-attendee-modal">
              Close
            </button>
          </div>
        </div>
      )}




      {/* Bottom navigation bar */}
      {/* <BottomNavBar /> */}
    </div>
    </div>
  );
};

export default EventDetailsPage;
