import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';

const BottomNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [currentTab, setCurrentTab] = useState('/home');

  useEffect(() => {
    // Set the current tab based on the current URL path
    setCurrentTab(location.pathname);
  }, [location.pathname]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10, // Ensures it stays above other components
      }}
      elevation={5}
    >
      <BottomNavigation
        value={currentTab}
        onChange={(event, newValue) => {
          setCurrentTab(newValue);
          handleNavigate(newValue);
        }}
        sx={{
          backgroundColor: '#020420', // Dark background
          height: 80, // Increase height
        }}
      >
        <BottomNavigationAction
          label="Home"
          value="/home"
          icon={<HomeIcon />}
          sx={{
            color: currentTab === '/home' ? 'primary.main' : '#fff',
            fontSize: '1.2rem',
            '& .MuiBottomNavigationAction-label': {
              fontSize: '1rem', // Bigger label
            },
            '& .MuiSvgIcon-root': {
              fontSize: '2rem', // Bigger icon
            },
          }}
        />
        <BottomNavigationAction
          label="Events"
          value="/events"
          icon={<EventIcon />}
          sx={{
            color: currentTab === '/events' ? 'primary.main' : '#fff',
            fontSize: '1.2rem',
            '& .MuiBottomNavigationAction-label': {
              fontSize: '1rem', // Bigger label
            },
            '& .MuiSvgIcon-root': {
              fontSize: '2rem', // Bigger icon
            },
          }}
        />
        <BottomNavigationAction
          label="Profile"
          value="/profile"
          icon={<PersonIcon />}
          sx={{
            color: currentTab === '/profile' ? 'primary.main' : '#fff',
            fontSize: '1.2rem',
            '& .MuiBottomNavigationAction-label': {
              fontSize: '1rem', // Bigger label
            },
            '& .MuiSvgIcon-root': {
              fontSize: '2rem', // Bigger icon
            },
          }}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavBar;
